import React, { useState } from 'react'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel';
import { contactInputs } from '../../../formsource';
import './contact.css'
import { contact_us_api } from '../../../services/user_apis';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TextToSpeech from '../../../services/textToSpeech';

const Countact_us = ({ socketData }) => {
    const { t } = useTranslation();
    const [desc, setdesc] = useState("");
    const [success, setSuccess] = useState(false)
    // const [success,setsuccess] = useState(false);
    const [contactinput, setContactinput] = useState({
        first_name: "",
        last_name: "",
        email: "",
        subject: "",
    })
    const changeval = async (value) => {
        // console.log(value)
        // setdesc()
    }
    const handlesubmit = async () => {
        document.getElementsByClassName("form-control").value = ""
        try {
            let data = {
                contact_first_name: contactinput.first_name,
                contact_last_name: contactinput.last_name,
                contact_email: contactinput.email,
                contact_subject: contactinput.subject,
                contact_description: "descccc"
            }
            const response = await contact_us_api(data)
            if (response.code === 1) {
                setSuccess(true)
                setContactinput({
                    first_name: "",
                    last_name: "",
                    email: "",
                    subject: "",
                });
                setdesc("")
            }
        }
        catch (e) {
            console.log(e)
        }
    }
    const [focused, setFocused] = useState(false)
    const add_contact_form = (e) => {
        setContactinput({ ...contactinput, [e.target.name]: e.target.value });
    }
    const handleFocus = (e) => {
        setFocused(true)
    }
    return (
        <React.Fragment>

            <div className="content">
                <div className="left-portion">
                    <Left_Panel />
                </div>
                <div className="middle-portion">
                    <Home_Header socketData={socketData} />
                    <div className="container-fluid ">
                        <section className="page-banner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="banner-title">{t("Contact Us")}</h2>
                                        <div className="bread-crumbs">
                                            <Link to="/home" title={t("Home")}><TextToSpeech text={t("Home")} /></Link> <span></span> <TextToSpeech text={t("Contact Us")} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="pt-4">
                            <div className="container">

                                {success && <h3 className='text-success text-center'><TextToSpeech text={t("Request has been sent successfully")} /></h3>}
                                <div className="row justify-content-center">
                                    <div className="col-lg-8 text-left">
                                        <div className="contact-form" role="tablist">
                                            {contactInputs.map((input, i) => (

                                                <div key={i} className="form-group" role="presentation">
                                                    <label for={t("Contact Form")} className="hidden">{t("Contact Form")}</label>
                                                    <input {...input} className="form-control" onChange={add_contact_form} value={contactInputs.value} onBlur={handleFocus} focused={focused.toString()} />
                                                    <span className='text-danger mt-4'><TextToSpeech text={input.errorMessage} /></span>
                                                </div>
                                            ))}

                                            <div className="form-group">
                                                <label for={t("Description")} className="hidden">{t("Description")}</label>
                                                <textarea className="form-control" rows="5" placeholder={t("Description" )}onChange={(e) => setdesc(e.target.value)} required value={desc} ></textarea>
                                                <span className='text-danger mt-4'><TextToSpeech text={t("Description is required")} /></span>
                                            </div>
                                            <div className="form-group text-center">
                                                <label for={t("Submit")} className="hidden">{t("Submit")}</label>
                                                <a  title={t("Submit")} className="cybersmart-btn btn-block" onClick={handlesubmit}><TextToSpeech text={t("Submit")} /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                <Modal />
            </div>
        </React.Fragment>
    )
}

export default Countact_us
