import React, { useEffect } from 'react'
import env from 'react-dotenv'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import myclassbg from "./my-class-img/myclassbg.png"
import { CircularProgress } from '@mui/material';
// import { Button } from '@material-ui/core';
import { Button } from '@mui/material';
import { delete_announcement, teacher_class_division_list } from '../../../services/user_apis'
import { home_work_list } from '../../../services/user_apis';
import { user_list_api ,user_list_student_api} from '../../../services/user_apis';
import { lecture_list_api } from '../../../services/user_apis';
import { user_annoucement_api, postListCategoryWise, create_chat_room, announcement_list, teacher_list, school_detail } from '../../../services/user_apis'
import { Link, useNavigate } from 'react-router-dom';
import { division_details_api } from '../../../services/user_apis';
import PostListing from "../home/PostListing";
import moment from 'moment'
import $ from "jquery"
import { useTranslation } from 'react-i18next'
import TextToSpeech from '../../../services/textToSpeech'

const My_Class_Teacher = ({ socketData }) => {

    const { t } = useTranslation();
    let user = JSON.parse(localStorage.getItem('user'));
    const [ datalimit, setDatalimit ] = React.useState({ limit: 10, page: 1 });
    const [circular, setcircular] = React.useState(true)
    let [class_list, setClassList] = React.useState([])
    let [post, setpost] = React.useState([])
    let [myfriends, setmyfriends] = React.useState([])
    let [myClassStudent, setmyClassStudent] = React.useState([])
    let [lecture, setlecturelist] = React.useState([])
    let navigate = useNavigate();
    let [classDetails, setClassDetails] = React.useState({});
    let [selectClassData, setSelectClassData] = React.useState(null);
    const [reportPostData, setReportPostData] = React.useState("")
    const [announcementList, setAnnouncementList] = React.useState("")
    let [tab, setTab] = React.useState("posts");
    let [divisionDetails, setDivisionDetails] = React.useState(null)
    let [instructionData, setInstructionData] = React.useState("");
    let [schoolData, setschooldata] = React.useState([]);
    let [uploadImagetype, steUploadImageType] = React.useState("cover");
    let [reportType, setReportType] = React.useState("");


    const user_list = async (classId,divisionId) => {
        try {
            const response = await user_list_student_api(classId,divisionId)
            // console.log("Get Selected Student List",response)
            if (response.code === 1) {
                // console.log("Get Selected Student List",response.data)
                setmyClassStudent(response.data)
            }
        }
        catch (e) {
            navigate('/')
        }
    }

    const classMember_list = async (division_id) => {
        // console.log('classMember_list', user);
        let post_body = {
            school_id: user.school_id,
        }
        const response = await teacher_list(post_body)
        // console.log("teacher list:: ===> ", response)
        if (response.code === 1) {
            setmyfriends(response.data)
        } else {
            setmyfriends([])
        }

    }

    useEffect(() => {
        school_details()
        // class_listApi()
    }, [])

    useEffect(() => {
        class_listApi()
    }, [datalimit])

    const school_details = async () => {

        const school_data = await school_detail(user.school_id)
        if (school_data.code === 1) {
            setschooldata(school_data.data);
        } else {
            setschooldata("")
        }

    }
    const class_listApi = async () => {
        // setcircular(true)
        if (user.user_role === "teacher") {
            const response = await teacher_class_division_list()
            if (response.code === 1) {
                // console.log("Class List Api Teacher", response)
                setClassList(response.data)
                if (response.data.length >= 1) {
                    setClassData(response.data[0])
                    setSelectClassData(response.data[0])
                    post_list_detail(response.data[0].class_id,response.data[0].division_id)
                    user_list(response.data[0].class_id,response.data[0].division_id)
                    classMember_list(response.data[0].division_id)
                    announcement_listApi(response.data[0].class_id, response.data[0].division_id)
                    getDivisionDetails(response.data[0].division_id)
                }
            }
        }
        // setcircular(false)
    }

    const post_list_detail = async (classId,divisionId) => {
        // setcircular(true
        const user = JSON.parse(localStorage.getItem('user'))
        var body = {
            ...{ "post_category": "class", "class_id": classId, "division_id": divisionId },
            ...datalimit
        };
        try {
            const response = await postListCategoryWise(body)
            if (response.code === 1) {
                setpost(response.data)
                // setcircular(false)
            }
        }
        catch (e) {
            navigate('/')
        }
        setcircular(false)
    }

    const changeClassSelection = async(event) => {
        setcircular(true);
        var selectData = class_list.filter((item) => item.user_class_division_id == event.target.value);
        // console.log(event.target.value);
        setClassData(selectData[0])
        setSelectClassData(selectData[0])
        // console.log("Change Id", selectData);
        setpost([])
        await post_list_detail(selectData[0].class_id,selectData[0].division_id)
        announcement_listApi(selectData[0].class_id, selectData[0].division_id)
        setmyfriends([])
        setmyClassStudent([])
        user_list(selectData[0].class_id,selectData[0].division_id)
        classMember_list(selectData[0].division_id)
        setDivisionDetails(null)
        getDivisionDetails(selectData[0].division_id)
        // setcircular(false)
    }

    const setClassData = (data) => {
        setClassDetails({ "class_name": data.class_name, "school_name": user.school_name, "division_name": data.division_name, "class_id": data.class_id, "division_id": data.division_id })
    }

    const announcement_listApi = async (classID, divisionID) => {
        let post_body = {
            class_id: classID,
            division_id: divisionID,
            school_id: user.school_id
        }
        const response = await announcement_list(post_body)
        if (response.code === 1) {
            setAnnouncementList(response.data)
        } else {
            setAnnouncementList("")
        }
    }

    const handleMore = (announcement_id) => {
        $(`.menu-` + announcement_id).toggleClass('more-active');
    };

    const handleDeleteClass = async (announcement_id) => {
        let post_body = {
            announcement_id: announcement_id
        }

        const response1 = await delete_announcement(post_body)
        if (response1.code === 1) {
            $(`.menu-` + announcement_id).removeClass('more-active');
            announcement_listApi(classDetails.class_id, classDetails.division_id)
        }
    }

    const handleEditClass = (announcData) => {
        navigate("/edit-announcement/e&" + classDetails.class_id + "&" + classDetails.division_id, { state: { announcData, isEdit: true } })
    }

    const clickHomeWorkCreate = () => {
        navigate("/add-announcement/c&" + classDetails.class_id + "&" + classDetails.division_id)
    }

    const createChatRoom = async (receiver_user) => {
        let post_body = {
            sender_id: user.user_id,
            sender_type: user.user_role,
            receiver_type: "teacher",
            school_id: user.school_id,
            receiver_id: receiver_user.teacher_id,
        }
        const response = await create_chat_room(post_body)
        if (response.code === 1) {
            setTimeout(() => {
                // console.log("socket execute")
                socketData({ user_id: receiver_user.teacher_id, user_role: 'teacher' })
            }, 1000);
            $(".chat-with-friend").addClass("chat-with-desk-friend-active");
        }
    }

    const createChatRoomStudent = async (receiver_user) => {
        let post_body = {
            sender_id: user.user_id,
            sender_type: user.user_role,
            receiver_type: "student",
            school_id: user.school_id,
            receiver_id: receiver_user.user_id,
        }
        const response = await create_chat_room(post_body)
        if (response.code === 1) {
            setTimeout(() => {
                socketData({ user_id: receiver_user.user_id, user_role: user.user_role })
            }, 1000);
            $(".chat-with-friend").addClass("chat-with-desk-friend-active");
        }
    }


    const getDivisionDetails = async (division_id) => {
        let post_body = {
            division_id: division_id
        }
        try {
            const response = await division_details_api(post_body)
            if (response.code === 1) {
                setDivisionDetails(response.data)
            }
        }
        catch (e) {
            navigate('/')
        }

    }

    const getPassDataOtherUser = (data) => {
        var dataPass = { "user_id": data.teacher_id, "username": data.username, "display_user_name": data.display_user_name, "user_about_me": data.about_me, "avatar_full_path": data.avatar }
        return dataPass
    }

    const updateLimit = () => {
        setcircular(true)
        setDatalimit({...datalimit, "limit": Number(datalimit.limit) + 10 })
    }

    const pagignation = () => {
        if (circular) {
            return <CircularProgress />
        }
        if (!circular) {
            if (post.length < datalimit.limit) {
                return <div className="no-more-activities"><TextToSpeech text={t('No more activities found.')} /></div>
            }
            return <Button variant="outlined" size="medium" onClick={updateLimit}><TextToSpeech text={t('See More')} /></Button>
        }
    }

    return (
        <React.Fragment>

            <div className="content">
                <div className="left-portion">
                    <Left_Panel />
                </div>
                <div className="middle-portion">
                    <Home_Header socketData={socketData} />
                    <div className="container-fluid text-left">
                        <div className="row">
                            <div className="col-lg-8 pr-md-0">
                                <div className="main-content">
                                    <div className='my-profile school-profile' role="tabpanel">
                                        {divisionDetails && !divisionDetails.class_teacher_ids.includes(user.user_id) ?
                                            <>
                                                <div className="p-banner" style={{ backgroundimage: `url(${(!divisionDetails.division_cover_image_full_path) ? myclassbg : divisionDetails.division_cover_image_full_path})` }}>
                                                    <img alt={t("Cover Image")} src={(!divisionDetails.division_cover_image_full_path) ? myclassbg : divisionDetails.division_cover_image_full_path} />

                                                </div>
                                                <div className="p-icon">
                                                    <div className="p-main-pic">
                                                        <img alt={t("Division Cover Image")} src={(!divisionDetails.division_logo_image_full_path) ? `${env.FRIST_CLASS_SVG}first-class.svg` : divisionDetails.division_logo_image_full_path} />
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="p-banner" >
                                                    <img alt={t("Class Cover Image")} src={(!divisionDetails || !divisionDetails.division_cover_image_full_path) ? myclassbg : divisionDetails.division_cover_image_full_path} />
                                                    <span role="button" className="cover-photo-change" data-target="#change_coverImage" data-toggle="modal" onClick={() => steUploadImageType("cover")}><TextToSpeech text={t('Choose different Cover Photo')} /></span>
                                                </div>
                                                <div className="p-icon">
                                                    <div className="p-main-pic" >
                                                        <img alt={t("Class Logo Image")} src={(!divisionDetails || !divisionDetails.division_logo_image_full_path) ? `${env.FRIST_CLASS_SVG}first-class.svg` : divisionDetails.division_logo_image_full_path} />
                                                        <span role="button" className="cover-photo-change logo-image" data-target="#change_coverImage" data-toggle="modal" onClick={() => steUploadImageType("logo")}><TextToSpeech text={t('Choose Logo')} /></span>
                                                    </div>
                                                </div>
                                            </>}
                                        <div className="p-name pb-3 d-flex justify-content-center alignItems-center">
                                            {
                                                user.user_role === "teacher" && <div className="my-class-dropdown">
                                                    <label for={t("Change Class")} className="hidden">{t("Change Class")}</label>
                                                    <select className="form-control" onChange={changeClassSelection}>
                                                        {class_list.map((value, i) => (

                                                            <option key={i} value={value.user_class_division_id}><TextToSpeech text={`${value.class_name} - ${value.division_name}`} /></option>
                                                        ))}

                                                    </select>
                                                </div>
                                            }
                                        </div>
                                        <br></br>
                                        <div className="post-content">
                                            <ul role="tablist" className="nav nav-tabs">
                                                <li role="presentation"><a href="#posts" data-toggle="tab" title={t('Posts')} className="active" onClick={() => setTab("posts")} ><TextToSpeech text={t('Posts')} /></a></li>
                                                <li role="presentation"><a href="#info" data-toggle="tab" title={t('Info')} onClick={() => setTab("info")} > <TextToSpeech text={t('Info')} /></a></li>
                                                <li role="presentation"><a href="#student" data-toggle="tab" title={t('Students')} onClick={() => setTab("student")} > <TextToSpeech text={t('Students')} /></a></li>
                                                <li role="presentation"><a href="#announcement" title={t('Announcements')} data-toggle="tab" onClick={() => setTab("announcement")} > <TextToSpeech text={t('Announcements')} /></a></li>
                                            </ul>
                                            {tab === "announcement" ?
                                                <div className="profile-tab-rightside mr-1">
                                                    <div className="createnewclass-btn">
                                                        <a href="javaScript:" title={t('Create New Announcement')} data-target="#ADDClass_Modal" onClick={clickHomeWorkCreate}><TextToSpeech text={t('Create New Announcement')} /></a>
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="posts">
                                            { 
                                                post.length > 0 ?
                                                <div className="profile-info">
                                                    <PostListing data={post}
                                                        callBackData={(data) => {
                                                            setReportType(data.type)
                                                            setReportPostData(data.reportdata)
                                                        }}
                                                    />
                                                </div>
                                                : 
                                                <div></div>
                                            }
                                            <br />
                                            <center>{pagignation()}</center>
                                            <br />
                                            <br />
                                        </div>
                                        <div className="tab-pane" id="info">
                                            <div className="about-profile-info">
                                                <ul role="tablist">
                                                    <li role="presentation">
                                                        <p><TextToSpeech text={t('Name of Class')} />: <b style={{ 'color': 'black' }}><TextToSpeech text={classDetails.class_name + " - " + classDetails.division_name} /></b></p>
                                                    </li>
                                                    <li role="presentation">
                                                        <p><TextToSpeech text={t('School')} />: <b style={{ 'color': 'black' }}><TextToSpeech text={classDetails.school_name} /></b></p>
                                                    </li>
                                                    {/* <li>
                                                        <p>Teacher in Class: <b style={{ 'color': 'black' }}>{myfriends.length}</b></p>
                                                    </li> */}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="student">                                            
                                            <div className="about-profile-info">
                                                <div className="mem-list">
                                                    <ul role="tablist">
                                                        {myClassStudent && myClassStudent.map((value, i) => (
                                                            <li key={i} role="presentation">
                                                               <div className='myclass-member-view add-friend-section'>
                                                                    <img alt={`${value?.display_user_name != "" ? value?.display_user_name : value?.username} ${t("image")}`} src={value?.avatar_full_path != null ? `${value?.avatar_full_path}` : `${env.PRO_IMG}pro-img.png`} />
                                                                    <Link title={`${value?.display_user_name != "" ? value?.display_user_name : value?.username} ${t("profile")}`} to="/otherUserProfile" state={{ "otherUserData": getPassDataOtherUser(value) }}>
                                                                    <label for={t("Add Friend")} className="hidden">{t("Add Friend")}</label>
                                                                    <button className="add-friend" style={{"margin-top":"15px"}}> <TextToSpeech text={value?.display_user_name != "" ? value?.display_user_name : value?.username} /> </button>
                                                                    </Link>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane" id="announcement">

                                            <div className="row justify-content-start pt-4" role="tabpanel">

                                                {announcementList && announcementList.map((value, index) => (
                                                    <div className="col-lg-6 mb-3" key={index} role="tabpanel">
                                                        <div className="other-class-box">
                                                            <div className="row w-100">
                                                                <div className="col-md-2 annoucement-img">
                                                                    <img src="assets/web/annoucement.png" alt={t("Announcement")} />
                                                                </div>
                                                                <div className="col col-md-10 picnic">
                                                                    <label><TextToSpeech text={value.title} /></label>
                                                                    <div className="picnic-team">
                                                                        {/* <img src="assets/web/team.png" /> */}
                                                                    </div>
                                                                    <div className="picninc-poster">
                                                                        <img className='w-100' height={'200px'} alt={t("Announcement")} style={{ "object-fit": "cover" }} src={value.announcement_image_full_path} />
                                                                    </div>
                                                                    <div className="register announcemetn-description">
                                                                        <p className="clear-style"><TextToSpeech text={value.description} /></p>
                                                                    </div>
                                                                    {value.description.length > 180 ? <a className="add-friend " href="javaScript:" data-target="#Instruction_Modal" data-toggle="modal" onClick={() => setInstructionData(value?.description)} ><TextToSpeech text={t('Read More')} /></a> : null}
                                                                </div>
                                                            </div>

                                                            <div className="more">
                                                                <div className={`more-option bottom-more-option menu-${value?.announcement_id}`}>
                                                                    <div className="delete">
                                                                        <a href='javaScript:' title={t('Delete Announcement')} onClick={() => handleDeleteClass(value.announcement_id)}>  <TextToSpeech text={t('Delete Announcement')} /></a>
                                                                    </div>
                                                                    <div className="edit">
                                                                        <a href="javaScript:" title={t('Edit Announcement')} data-target="#ADDClass_Modal" onClick={() => handleEditClass(value)} ><TextToSpeech text={t('Edit Announcement')} /></a>
                                                                    </div>
                                                                </div>
                                                                <a href='javaScript:' title={t("Add Edit Announcement")} onClick={() => handleMore(value?.announcement_id)}><img alt={t("Combined Shape")} src={`${env.COMBINED_SHAPE_PNG}combined-shape-2.png`} /></a>
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="right-side">

                                    <div className="school-right" data-message={`${schoolData.school_name} ${t("Details")}`} role="tabpanel">
                                        <h3><TextToSpeech text={t('School')} /></h3>
                                        <div className="school-img">
                                            {schoolData != "" &&
                                                <>
                                                    <img alt={`${schoolData.school_name} ${t("Logo")}`} src={schoolData.school_logo_full_path} className="s-img" />
                                                    <div className="school-name">
                                                        <h4><TextToSpeech text={schoolData.school_name} /></h4>
                                                        <h4><img alt={t("Location")} src="assets/web/location-1.png" /><TextToSpeech text={schoolData.school_location} /></h4>

                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="member-right">
                                        <h3><TextToSpeech text={`${t('Teachers in this School')} (${myfriends.length})`} /></h3>
                                        <div className="mem-list">
                                            <ul role="tablist">
                                                {myfriends && myfriends.map((value, i) => (
                                                    <li key={i} role="presentation">
                                                        <div className='myclass-member-view add-friend-section'>
                                                            <img alt={`${t("Avatar")} ${value?.display_user_name != "" ? value?.display_user_name : value?.username}`} src={value?.avatar != null ? `${value?.avatar}` : `${env.PRO_IMG}pro-img.png`} />

                                                            <Link title={`${value?.display_user_name != "" ? value?.display_user_name : value?.username} ${t("Profile")}`} to="/otherUserProfile" state={{ "otherUserData": getPassDataOtherUser(value) }}>
                                                                <p><TextToSpeech text={value?.display_user_name != "" ? value?.display_user_name : value?.username} /></p>
                                                            </Link>
                                                            {value.teacher_id != user.user_id &&
                                                                <><label for={t("Chat")} className="hidden">{t("Chat")}</label><button onClick={() => createChatRoom(value)} className="add-friend">
                                                                    <TextToSpeech text={t('Chat')} />
                                                                </button></>}
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <Modal report_type={reportType} report_data={reportPostData} isUpdate={(data) => (data === true && setReportPostData(""), getDivisionDetails(divisionDetails.division_id))} instructrionData={instructionData} divisionDetails={divisionDetails} uploadImagetype={uploadImagetype} />


        </React.Fragment >
    )
}

export default My_Class_Teacher
