import React,{useRef, useEffect} from 'react'
import env from 'react-dotenv';
import { Link, useNavigate } from 'react-router-dom';
import { friend_reason_list, user_logout } from '../../../services/user_apis';
import { notification_list, notification_change_status, mark_as_read ,survey_details,submit_survey} from '../../../services/user_apis';
import $ from 'jquery'
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import  NotificationSound from "../../../notification_sound.mp3"
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { addLanguage, removeLanguage } from '../../../store/languageSlice';
import Modal from '../modal/Modal';
import TextToSpeech from '../../../services/textToSpeech';
import TextProcessor from '../../../common/text-processor';


const Home_Header = ({ socketData }) => {

    const audioPlayer = useRef(null);
    const audioRef = useRef(null);
    const audioUrl = useSelector((state) => state.textspeech);
    const language = useSelector((state) => state.language);
    const speak = localStorage.getItem('speak') === 'true' ? true : false;

    const { t, i18n } = useTranslation();
    const [notificationlist, setNotificationList] = React.useState([]);
    const [notificationCount, setNotificationCount] = React.useState("");
    const [friendreasonList, setFriendReasonList] = React.useState([])
    const [sendRequestData, setSendRequestData] = React.useState(null)
    const dispatch = useDispatch();

    const navigate = useNavigate()
    let languages = [
        { id: 1, name: "English", value: "en" },
        // { id: 2, name: "عربي", value: "ar" },
        // { id: 3, name: "हिंदी", value: "hin" },
        { id: 4, name: "Türkçe", value: "tu" },
    ]

    const user = JSON.parse(localStorage.getItem('user'))

    useEffect(() => {
        if (!audioUrl && !audioUrl.value && audioRef.current) {
            audioRef.current.setAttribute('src', audioUrl.value);
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
        if (audioUrl && typeof audioUrl.value !== 'undefined' && audioUrl.value && audioRef.current) {
            audioRef.current.setAttribute('src', audioUrl.value);
            if (typeof audioRef.current !== 'undefined') {
                audioRef.current.play();
            } else {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
        }
      }, [audioUrl]);


    const logout = async () => {
        localStorage.removeItem('user');
        localStorage.removeItem('isLogin');
        localStorage.removeItem('language');
        localStorage.removeItem('userId');
        localStorage.removeItem("nCount");
        await user_logout(user)
        let path = window.location.protocol + "//" + window.location.host + ""
        window.location.href = path;
    }

    React.useEffect(() => {
        myNotification();
    }, [])

    React.useEffect(() => {
        let seleted_language = localStorage.getItem("language")
        $("#" + seleted_language).attr("selected", true)
        const interval = setInterval(() => {
            myNotification()
        }, 15000);
        return () => clearInterval(interval);
    }, [])


    const myNotification = async () => {
        const response = await notification_list({ unread: true })
        if (response.code === 1) {
            var oldCount=localStorage.getItem("nCount");

            setNotificationList(response.data.slice(0, 4))
            setNotificationCount(response.data.filter((item) => item.is_unread === 1))
           var countNew=response.data.filter((item) => item.is_unread === 1).length;
            
           if(countNew!=oldCount){
                localStorage.setItem("nCount",countNew);
                audioPlayer.current.play();
            }

        } else if (response.code === 2) {
            setNotificationList([])
        }
    }

    function changeLanguage(e) {
        i18n.changeLanguage(e.target.value);
        localStorage.setItem("language", e.target.value)
        dispatch(addLanguage(e.target.value));
    }

    const redirectNotification = (item) => {
        if (item.action === "add_announcement") {
            navigate("/my-class")
        } else if (item.action === "add_homework") {
            navigate("/my-homework")
        } else if (item.action === "post_comment") {
            navigate(`/comment/${item.data.post_id}`)
        }else if(item.action === "post"){
            navigate(`/post/${item.data.post_id}`)
        }else if (item.action=="online_class"){
            navigate("/my-homework")
        }
        else if(item.action=="homework_submission"){
            navigate("/my-homework-teacher")
        }
        else if(item.action === "survey"){
            callAPISurveyCheck(item)
        }else if(item.action=="add_report"){
            if( user.user_role === "teacher"){
                navigate("/reportAction")
            }
        }else if(item.action=="offline_chat"){
            onClickClariaBot();
        }
    }

    const onClickClariaBot = (event) => {
        $('.clarie').removeClass('clarie-active')
        $('.chat-with-friend').addClass('chat-with-desk-friend-active')
    }

    const callAPISurveyCheck= async (item) => {
        const response = await survey_details({"survey_id":item.action_id})
        if (response.code === 1) {
            showSurveyPopup(response.data)
        }
        
    }

    const showSurveyPopup = (item)=>{
        Swal.fire({
            title: t(item.title),
            text: t(item.description),
            input: 'radio',
            inputOptions: {"1":item.option1,"2":item.option2,"3":item.option3,"4":item.option4},
            inputValidator: (value) => {
              if (!value) {
                return t('You need to choose something!')
              }
            }
          }).then((result) => {
            setSurveyUser(item,result.value)
          })
    }

    const setSurveyUser = async(item,value)=>{
        var useranswer=item.option4
        if(value === 1){
            useranswer=item.option1
        }else if(value === 2) {
            useranswer=item.option2
        }
        else if(value === 3) {
            useranswer=item.option3
        }                
        var userid=localStorage.getItem("userId");

        const response = await submit_survey({"surevy_id":item.id,"user_id":userid,"user_answer":useranswer})
        if (response.code === 1) {
            Swal.fire({
                title: t('Cybersmarties'),
                text: t(response.message),
                confirmButtonText: t('Okay')
              })
        }
    }

    const callMarkAsAllRead = async () => {
        const response = await mark_as_read()
        setNotificationCount("");
        if (response.code === 1) {
            myNotification()
        } else if (response.code === 2) {
            myNotification()
        }
    }

    const searchButton= (event)=>{
        navigate("/add-classmate-search",{state:{"data":document.getElementById("searchData").value}})
    }

    const notificationChangeStatus = async (userid, status, notificatonid) => {
        const user = JSON.parse(localStorage.getItem('user'))
        const post_body = {
            from_user_id: userid,
            friend_status: status,
            notification_id: notificatonid,
            school_id: user.school_id
        }
        const response = await notification_change_status(post_body)
        if (response.code === 1) {
            myNotification()
        } else if (response.code === 2) {
            myNotification()
        }
        if (status === "accepted") {
            setTimeout(() => {
                // console.log("socket exicute")
                socketData({ user_id: userid, user_role: 'student' })
            }, 2000);
        }
    }

    const friend_list_reason_api = async () => {
        const response = await friend_reason_list()
        if (response.code === 1) {
            setFriendReasonList(response.data)
            // console.log("friend reason list   ====> ", response.data)
        }

    }

    const setData = async (item) => {
        friend_list_reason_api()
        setSendRequestData(item)
    }

    return (
        <React.Fragment>
            <audio ref={audioPlayer} src={NotificationSound} />
            <header>
                <div className="h-header">
                    <div className="logo" araia-label={t("Cybersmarties logo")}>
                    <audio ref={audioRef} controls style={{ visibility: 'hidden', width: 0 }}>
                        {audioUrl && typeof audioUrl.value !== 'undefined' && <source src={audioUrl.value} type="audio/mp3" style={{ display: 'none' }} />}
                        Your browser does not support the audio element.
                    </audio>
                    </div>
                    <div className="search-content">
                        {
                            user.user_role === "teacher" ?
                            <></>
                            :
                            <>
                                <div className="search-box">
                                    <label for="search" className="hidden"><TextToSpeech text={t("Search Friends")} /></label>
                                    <input className="search" role="search" aria-label={t("Search friends")} placeholder={t("Search Friends")} id="searchData" />
                                </div>
                                <div className="search-position">
                                    <button role="button" aria-label={t("Search friend button")} className="search-icon" onClick={searchButton}></button>
                                </div>
                            </>
                        }        
                    </div>
                    <div className="social">
                        <div className="search search-header" role="search" aria-label={t("Cybersmarties search box")}><a  title={t("Cybersmarties search box")}><img alt={t("Cybersmarties search box")} src={"../assets/web/search-1.png"} /> <TextToSpeech text={t('Search')} /></a>
                        </div>
                        <div className="form-group m-0 mr-3">
                            <div className="report-message-dropdown ">
                                <label className="hidden" for="language-chooser">{t("Language selection")}</label>
                                <select className="form-control reportMessage" onChange={changeLanguage} aria-label={t("Language selection")}>
                                    {languages.map((item, i) => (
                                        <option key={i} value={item.value} id={item.value}>{item.name}</option>
                                    ))}

                                </select>
                            </div>
                        </div>
                        <div className="notification">
                            <div className="comment-right">
                                <div className="more notification_more">

                                    <div className="notify-list more-option text-start" aria-label={t("Notification list")} role="list">
                                        {notificationlist.length > 0 ?
                                            notificationlist.map((item, i) => {
                                                if (item.action === "friend_request") {
                                                    return (
                                                        <div className=" w-100 my-1 px-5 py-2 box" key={i} aria-label={t("Friend Request")} role="tab" tabindex={i+1}>
                                                            <div className="flex-nowrap row alignItems-center">
                                                                <img alt={`${t("Profile image")} of ${typeof item.sender_detail.display_user_name !== 'undefined' ? item.sender_detail.display_user_name : item.sender_detail.username}`} src={item.sender_detail.user_profile_full_path !== null ? item.sender_detail.user_profile_full_path : "../assets/web/friend.png"} style={{ width: 65, height: 65 }} />
                                                                <div className='w-100'>
                                                                    <p className="card-text">{(item.notification_message).substring(0, (item.notification_message).indexOf(' '))} <TextToSpeech text={t((item.notification_message).substring((item.notification_message).indexOf(' ') + 1))} /></p>
                                                                    {typeof item.data.status !== 'undefined' && item.data.status === "accepted" ?
                                                                        <p><TextToSpeech text={t('Request Accepted')} /></p>
                                                                        :
                                                                        <>
                                                                            <button onClick={() => setData(item)} className="btn btn-success mx-1" data-target="#friend-RequestModal" data-toggle="modal"><TextToSpeech text={t('Accept')} /></button>
                                                                            <button onClick={() => notificationChangeStatus(item.data.from_user_id, "rejected", item.notification_id)} className="btn btn-danger mx-1"><TextToSpeech text={t('Reject')} /></button>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                else if (item.action === "online_class") {
                                                    return (
                                                        <>
                                                        <Link to={"/my-homework"} state={{ homworkData: item.data }} key={i} role="tab" tabindex={i+1}>
                                                            <div className={` w-100 my-1 px-5 py-2 box ${item.is_unread === 1 ? "unread" : "read"} `} key={i}>
                                                                <div className="flex-nowrap row alignItems-center">
                                                                    <img alt={`${t("Online Class")} from ${typeof item.sender_detail.display_user_name !== 'undefined' ? item.sender_detail.display_user_name : item.sender_detail.username}`} src={item.sender_detail.user_profile_full_path !== null ? item.sender_detail.user_profile_full_path : "../assets/web/friend.png"} style={{ width: 65, height: 65 }} />
                                                                    <div className='w-100'>
                                                                        <p className="card-text"><TextToSpeech text={`${t(item.notification_title)} ${t('from')} ${(typeof item.sender_detail.display_user_name !== 'undefined' && item.sender_detail.display_user_name != "") ? item.sender_detail.display_user_name : item.sender_detail.username} `} /></p>
                                                                        <p className="card-text"><TextToSpeech text={`${TextProcessor.postNotification(item.notification_message)}`} /></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        </>
                                                    );
                                                }
                                                else if (item.action === "add_homework") {
                                                    return (
                                                        <>
                                                        <Link to={"/homeworkDetails"} title={`${t("Homework added")} from ${typeof item.sender_detail.display_user_name !== 'undefined' ? item.sender_detail.display_user_name : item.sender_detail.username}`} state={{ homworkData: item.data }} key={i} role="tab" tabindex={i+1}>
                                                            <div className={` w-100 my-1 px-5 py-2 box ${item.is_unread === 1 ? "unread" : "read"} `} key={i}>
                                                                <div className="flex-nowrap row alignItems-center">
                                                                    <img alt={`${t("Homework added")} from ${typeof item.sender_detail.display_user_name !== 'undefined' ? item.sender_detail.display_user_name : item.sender_detail.username}`} src={item.sender_detail.user_profile_full_path !== null ? item.sender_detail.user_profile_full_path : "../assets/web/friend.png"} style={{ width: 65, height: 65 }} />
                                                                    <div className='w-100'>
                                                                        <p className="card-text"><TextToSpeech text={`${t(item.notification_title)} ${t('from')} ${(typeof item.sender_detail.display_user_name !== 'undefined' && item.sender_detail.display_user_name != "") ? item.sender_detail.display_user_name : item.sender_detail.username} `} /></p>
                                                                        <p className="card-text"><TextToSpeech text={`${TextProcessor.postNotification(item.notification_message)}`} /></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        </>
                                                    );
                                                }
                                                else if (item.action === "add_report") {
                                                    return (
                                                        <>
                                                        <Link to={"/reportAction"} title={`${t("Report added")} from ${typeof item.sender_detail.display_user_name !== 'undefined' ? item.sender_detail.display_user_name : item.sender_detail.username}`} state={{ homworkData: item.data }} key={i} role="tab" tabindex={i+1}>
                                                            <div className={` w-100 my-1 px-5 py-2 box ${item.is_unread === 1 ? "unread" : "read"} `} key={i}>
                                                                <div className="flex-nowrap row alignItems-center">
                                                                    <img alt={`${t("Report added")} from ${typeof item.sender_detail.display_user_name !== 'undefined' ? item.sender_detail.display_user_name : item.sender_detail.username}`} src={item.sender_detail.user_profile_full_path !== null ? item.sender_detail.user_profile_full_path : "../assets/web/friend.png"} style={{ width: 65, height: 65 }} />
                                                                    <div className='w-100'>
                                                                        <p className="card-text"><TextToSpeech text={`${t(item.notification_title)} ${t('from')} ${(typeof item.sender_detail.display_user_name !== 'undefined' && item.sender_detail.display_user_name != "") ? item.sender_detail.display_user_name : item.sender_detail.username} `} /></p>
                                                                        <p className="card-text"><TextToSpeech text={`${TextProcessor.postNotification(item.notification_message)}`} /></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        </>
                                                    );
                                                }
                                                else if (item.action === "homework_submission") {
                                                    return (
                                                        <>
                                                        <Link title={`${t("Homework submitted")} from ${typeof item.sender_detail.display_user_name !== 'undefined' ? item.sender_detail.display_user_name : item.sender_detail.username}`} to={user.user_role === "student" ? "/homeworkDetails" : "/home-work-submit-details"} state={{ homworkData: item.data }} role="tab" tabindex={i+1}>
                                                            <div className={` w-100 my-1 px-5 py-2 box ${item.is_unread === 1 ? "unread" : "read"} `} key={i}>
                                                                <div className="flex-nowrap row alignItems-center">
                                                                    <img src={item.sender_detail.user_profile_full_path !== null ? item.sender_detail.user_profile_full_path : "../assets/web/friend.png"} style={{ width: 65, height: 65 }} />
                                                                    <div className='w-100'>
                                                                        <p className="card-text"><TextToSpeech text={`${t(item.notification_title)} ${t('from')} ${(typeof item.sender_detail.display_user_name !== 'undefined' && item.sender_detail.display_user_name != "") ? item.sender_detail.display_user_name : item.sender_detail.username} `} /></p>
                                                                        <p className="card-text"><TextToSpeech text={`${TextProcessor.postNotification(item.notification_message)}`} /></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        </>
                                                    );
                                                }
                                                else if (item.action === "post_comment") {
                                                    return (<>
                                                        <div aria-label={`${t("Comment")} from ${typeof item.sender_detail.display_user_name !== 'undefined' ? item.sender_detail.display_user_name : item.sender_detail.username}`} onClick={() => redirectNotification(item)} className={` w-100 my-1 px-5 py-2 box ${item.is_unread == 1 ? "unread" : "read"} `} role="tab" tabindex={i+1}>
                                                            <div class="flex-nowrap row align-items-center">
                                                                <img alt={`${t("Comment")} from ${typeof item.sender_detail.display_user_name !== 'undefined' ? item.sender_detail.display_user_name : item.sender_detail.username}`} src={item.sender_detail.user_profile_full_path !== null ? item.sender_detail.user_profile_full_path : "../assets/web/friend.png"} style={{ width: 65, height: 65 }} />
                                                                <div className='w-100'>
                                                                    <p class="card-text"><TextToSpeech text={`${(typeof item.sender_detail.display_user_name !== 'undefined' && item.sender_detail.display_user_name != "") ? item.sender_detail.display_user_name : item.sender_detail.username} ${t('has')} ${TextProcessor.postNotification(item.notification_title)}`} /></p>
                                                                    <p class="card-text"><TextToSpeech text={`${TextProcessor.postNotification(item.notification_message)}`} /></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>);
                                                }
                                                else {
                                                    return (<>
                                                        <div aria-label={`${t("Notification")} from ${(typeof item.sender_detail !== 'undefined' && typeof item.sender_detail.display_user_name !== 'undefined') ? item.sender_detail.display_user_name : ((typeof item.sender_detail !== 'undefined' && typeof item.sender_detail.username !== 'undefined') ? item.sender_detail.username : 'Admin')}`} onClick={() => redirectNotification(item)} className={` w-100 my-1 px-5 py-2 box ${item.is_unread == 1 ? "unread" : "read"} `} role="tab" tabindex={i+1}>
                                                            <div class="flex-nowrap row align-items-center">
                                                                <img alt={`${t("Notification")} from ${(typeof item.sender_detail !== 'undefined' && typeof item.sender_detail.display_user_name !== 'undefined') ? item.sender_detail.display_user_name : ((typeof item.sender_detail !== 'undefined' && typeof item.sender_detail.username !== 'undefined') ? item.sender_detail.username : 'Admin')}`} src={(typeof item.sender_detail !== 'undefined' && typeof item.sender_detail.user_profile_full_path !== 'undefined' && item.sender_detail.user_profile_full_path !== null) ? item.sender_detail.user_profile_full_path : "../assets/web/friend.png"} style={{ width: 65, height: 65 }} />
                                                                <div className='w-100'>
                                                                    <p class="card-text"><TextToSpeech text={`${t(item.notification_title)} ${t('from')} ${(typeof item.sender_detail !== 'undefined' && typeof item.sender_detail.display_user_name !== 'undefined' && item.sender_detail.display_user_name !== "") ? item.sender_detail.display_user_name : ((typeof item.sender_detail !== 'undefined' && typeof item.sender_detail.username !== 'undefined') ? item.sender_detail.username : 'Admin')} `} /></p>
                                                                    <p class="card-text"><TextToSpeech text={`${TextProcessor.postNotification(item.notification_message)}`} /></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>);
                                                }
                                            })
                                        :
                                            <div aria-label={t("No notifications")} className="non-content text-center my-2" tab="tab" tabindex={1}>
                                                {(user.user_role === 'student') ?
                                                    <>
                                                        <img alt="Friend image" src={`${process.env.PUBLIC_URL + "/" + env.FEELINGS_PNG}friend.png`} />
                                                        <p>
                                                        <TextToSpeech text={t('No New Friend Requests')} />
                                                        </p>
                                                        <p>
                                                        <TextToSpeech text={t('There are no new friend requests at this moment')} />
                                                        </p>
                                                    </>
                                                    :
                                                    <p><TextToSpeech text={t('There are no new notifications at this moment')} /></p>
                                                }
                                            </div>
                                        }
                                        <div className="view-all m-1">
                                            <div>
                                                <Link title={t('View All Updates')} to="/notification" className='text-center' onClick={() => $(".notify-list").removeClass("active")} role="tab" tabindex={1}>
                                                <TextToSpeech text={t('View All Updates')} />
                                                </Link>
                                            </div>
                                            <div>
                                                <a href='#' title={t('Mark All As Read')} className='text-center' onClick={() => callMarkAsAllRead()} role="tab" tabindex={2}>
                                                <TextToSpeech text={t('Mark All As Read')} />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <a  title={t('Notifications')} onClick={()=> {return false;}}>
                                        <img alt={t('Notifications')} src={`${process.env.PUBLIC_URL + "/" + env.FEELINGS_PNG}notification.png`} />
                                        <TextToSpeech text={t('Notifications')} />
                                        <div className='notification-badge' aria-label={`${notificationCount.length} ${t('Notifications')}`}><p className='badge-text'><TextToSpeech text={notificationCount.length} /></p></div>
                                    </a>
                                </div>
                            </div>

                        </div>
                        <Link to="/" title={t('Logout')} onClick={() => logout()} style={{ textDecoration: "none" }}>

                            <img alt={t('Logout')} src={`${process.env.PUBLIC_URL + "/" + env.LOGOUT_PNG}logout.png`} /> <TextToSpeech text={t('Logout')} />

                        </Link>
                        <div className="toggle" aria-label={t('Menu')}><a title={t('Menu')}><img alt={t('Menu')} src={`${process.env.PUBLIC_URL + "/" + env.MENU_PNG}menu.png`} />
                        <TextToSpeech text={t('Menu')} /></a></div>
                    </div>

                </div>
            </header>
            {sendRequestData ?
                <Modal isAcceptRequest={true} socketData={socketData} friend_reason_list={friendreasonList} send_friend_request_data={sendRequestData} isUpdate={(data) => {setSendRequestData(null); data === true ? myNotification(): setSendRequestData(null)}} onPrimaryButtonClick={()=> {setSendRequestData(null); myNotification()}}/>
            :null}
        </React.Fragment>
    )
}

export default Home_Header
