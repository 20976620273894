const GLOBAL_CONSTANT = {
    "CHAT_API": "https://api.chat.cybersmarties.com.tr",
    "API_URL": "https://api.cybersmarties.com.tr",
    "TIME_ZONE": "Asia/Istanbul",
    "OFFLINE": "false",
    "TEXT_SPEECH_API_URL": "https://aidennvz09.execute-api.eu-west-1.amazonaws.com",
}


export default GLOBAL_CONSTANT;
